@font-face {
    font-family: 'Poppins';
    src: url(/static/media/Poppins-ExtraBold.fc82f1d5.eot);
    src: url(/static/media/Poppins-ExtraBold.fc82f1d5.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Poppins-ExtraBold.05ae6af0.woff2) format('woff2'),
        url(/static/media/Poppins-ExtraBold.3feb7be2.woff) format('woff'),
        url(/static/media/Poppins-ExtraBold.38304399.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url(/static/media/Poppins-Light.44a3ba68.eot);
    src: url(/static/media/Poppins-Light.44a3ba68.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Poppins-Light.ec0c1f37.woff2) format('woff2'),
        url(/static/media/Poppins-Light.109b8c3d.woff) format('woff'),
        url(/static/media/Poppins-Light.31d0e6b2.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url(/static/media/Poppins-Regular.18928fae.eot);
    src: url(/static/media/Poppins-Regular.18928fae.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Poppins-Regular.661921de.woff2) format('woff2'),
        url(/static/media/Poppins-Regular.f543f112.woff) format('woff'),
        url(/static/media/Poppins-Regular.008d770c.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url(/static/media/Poppins-Bold.6c271a69.eot);
    src: url(/static/media/Poppins-Bold.6c271a69.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Poppins-Bold.60211f40.woff2) format('woff2'),
        url(/static/media/Poppins-Bold.3a170b0b.woff) format('woff'),
        url(/static/media/Poppins-Bold.eac74bb5.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url(/static/media/Poppins-Medium.7841fc1c.eot);
    src: url(/static/media/Poppins-Medium.7841fc1c.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Poppins-Medium.3645913d.woff2) format('woff2'),
        url(/static/media/Poppins-Medium.0cbde359.woff) format('woff'),
        url(/static/media/Poppins-Medium.9357b277.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url(/static/media/Poppins-SemiBold.c43358a7.eot);
    src: url(/static/media/Poppins-SemiBold.c43358a7.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Poppins-SemiBold.35895c08.woff2) format('woff2'),
        url(/static/media/Poppins-SemiBold.76dd8082.woff) format('woff'),
        url(/static/media/Poppins-SemiBold.30fb879a.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url(/static/media/Poppins-Thin.550fc6e8.eot);
    src: url(/static/media/Poppins-Thin.550fc6e8.eot?#iefix) format('embedded-opentype'),
        url(/static/media/Poppins-Thin.d1153a97.woff2) format('woff2'),
        url(/static/media/Poppins-Thin.dd92b7aa.woff) format('woff'),
        url(/static/media/Poppins-Thin.34ec4d7c.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
}


